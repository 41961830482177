import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';

import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';

const useStyles = makeStyles(() => ({
  redColor: { color: red[500] },
  greenColor: { color: green[500] }
}));

function ButtonToggleSign({ row, value, field }) {
  const classes = useStyles();

  const handleClick = () => {
    if (value === '+') {
      row.handleToggleOperator(row.id, field, '-');
    } else {
      row.handleToggleOperator(row.id, field, '+');
    }
  };

  return (
    <Button size="small" onClick={handleClick}>
      {value === '+' ? (
        <AddOutlinedIcon className={classes.greenColor} />
      ) : (
        <RemoveOutlinedIcon className={classes.redColor} />
      )}
    </Button>
  );
}

export default ButtonToggleSign;
