import { Checkbox, TableCell } from '@material-ui/core';
import React from 'react';

/**
 *
 * @param {object} param
 * @param {rowIDKey} param.id
 * @param {boolean} param.checked
 * @param {boolean} param.indeterminate
 * @param {string} param.className
 * @param {(id: rowIDKey, checked: boolean)} param.updateSelected
 * @returns
 */
const CellCheckBox = ({
  id,
  checked = false,
  indeterminate = false,
  className,
  updateSelected
}) => {
  return (
    <TableCell
      align="center"
      className={className}
      style={{
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        padding: 0
      }}
    >
      <Checkbox
        color="primary"
        checked={checked}
        indeterminate={indeterminate}
        onChange={event => updateSelected(id, event.target.checked)}
      />
    </TableCell>
  );
};

export default React.memo(CellCheckBox);
