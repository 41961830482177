import React, { useState } from 'react';
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  TextField,
  Box,
  Button,
  Modal,
  Chip,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SubAccForm from '../SubAccForm';
import DraggableField from './DraggableField';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
    cursor: 'move',
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  fieldContainer: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    border: '1px dashed #ccc',
    borderRadius: '4px',
    backgroundColor: '#f9f9f9',
    display: 'flex',
    alignItems: 'center'
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(3),
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    width: '90%',
    maxWidth: '500px'
  },
  deleteIcon: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer'
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export const ItemType = {
  WORD: 'WORD',
  FIELD: 'FIELD'
};

const DraggableChip = ({ word, label }) => {
  const classes = useStyles();
  const [{ isDragging }, dragRef] = useDrag({
    type: ItemType.WORD,
    item: { word },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      ref={dragRef}
    >
      <Chip
        label={word}
        className={classes.chip}
        style={{
          backgroundColor: isDragging ? '#ddd' : undefined
        }}
      />
      <Typography
        style={{
          fontSize: '0.6rem',
          color: '#888'
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

/**
 * @typedef {{barangay: string}} barangay
 */

/**
 * @typedef {{city: string, barangays: barangay[]}} city
 */

/**
 * @typedef {{province: string, cities:city[]}} province
 */

/**
 *
 * @param {Array<{stateProv:string,cityMuni:string,townBar:string}>} data
 * @returns
 */
function restructureData(data = []) {
  /**
   * @type {province[]}
   */
  const res = [];

  for (const row of data) {
    const provinceIndex = res.findIndex(
      item => item.province === row.stateProv
    );

    if (provinceIndex === -1) {
      res.push({
        province: row.stateProv,
        cities: [
          {
            city: row.cityMuni,
            barangays: [{ barangay: row.townBar }]
          }
        ]
      });
    } else {
      const cityIndex = res[provinceIndex].cities.findIndex(
        city => city.city === row.cityMuni
      );

      if (cityIndex === -1) {
        res[provinceIndex].cities.push({
          city: row.cityMuni,
          barangays: [{ barangay: row.townBar }]
        });
      } else {
        res[provinceIndex].cities[cityIndex].barangays.push({
          barangay: row.townBar
        });
      }
    }
  }

  return res;
}

const WordArrangeModal = ({
  dataLabels,
  dataEditLabel,
  dataUse,
  wordDict,
  onSubmit,
  error
}) => {
  const classes = useStyles();
  const [inputText, setInputText] = useState('');
  const [rawArrangedWords] = useState('');
  const [delimiter, setDelimiter] = React.useState(',');
  const usablefields = 3;

  const restructuredAddress = restructureData(dataUse);

  const [fields, setFields] = useState(
    dataLabels.map(field => ({ id: field, value: '' }))
  );

  const [chips, setChips] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const handleInputChange = event => {
    const text = event.target.value.toUpperCase();
    setInputText(text);
    processInput(text);
  };
  const handleFilterBlur = () => {
    const updatedText = inputText.toUpperCase();
    setInputText(updatedText);
    processInput(updatedText);
  };

  const arrWords = fields.map(field => field.value).join(', ');

  const processInput = text => {
    const wordText = text.split(`${delimiter}`).map(word => word.trim());

    const categorizedChips = {
      province: [],
      city: [],
      barangay: [],
      unmatched: []
    };

    function cleanArrayWords(array) {
      return array.map(word => word.replace(/-/g, ' '));
    }

    const provinces = restructuredAddress.map(item => item.province);
    const cleanedProvinces = cleanArrayWords(provinces);

    const detectedProvince = cleanedProvinces.find(province =>
      wordText.some(
        (_, index) =>
          wordText
            .slice(index, index + province.split(' ').length)
            .join(' ') === province
      )
    );

    let relevantData = null;
    if (detectedProvince) {
      relevantData = restructuredAddress.find(
        item => item.province.replace(/-/g, ' ') === detectedProvince
      );
      categorizedChips.province.push(detectedProvince);
    }

    const cities = relevantData
      ? cleanArrayWords(relevantData.cities.map(c => c.city))
      : [];
    const barangays = relevantData
      ? cleanArrayWords(
          relevantData.cities.flatMap(c => c.barangays.map(b => b.barangay))
        )
      : [];

    let i = 0;
    while (i < wordText.length) {
      let matched = false;

      for (let j = 3; j > 0; j--) {
        const sequence = wordText.slice(i, i + j).join(' ');
        if (cities.includes(sequence)) {
          categorizedChips.city.push(sequence);
          i += j;
          matched = true;
          break;
        } else if (barangays.includes(sequence)) {
          categorizedChips.barangay.push(sequence);
          i += j;
          matched = true;
          break;
        }
      }

      if (!matched) {
        if (cities.includes(wordText[i])) {
          categorizedChips.city.push(wordText[i]);
        } else if (barangays.includes(wordText[i])) {
          categorizedChips.barangay.push(wordText[i]);
        } else {
          categorizedChips.unmatched.push(wordText[i]);
        }
        i++;
      }
    }

    if (categorizedChips.province.length === 0) {
      categorizedChips.province.push("Can't find");
    }
    if (categorizedChips.city.length === 0) {
      categorizedChips.city.push("Can't find");
    }
    if (categorizedChips.barangay.length === 0) {
      categorizedChips.barangay.push("Can't find");
    }

    const sortedChips = [
      ...categorizedChips.province,
      ...categorizedChips.city,
      ...categorizedChips.barangay,
      ...categorizedChips.unmatched
    ];

    setFields(fields =>
      fields.map((field, index) => ({
        ...field,
        value: index < usablefields ? sortedChips[index] || '' : field.value
      }))
    );

    setChips(
      sortedChips.map(word => ({
        word,
        category: categorizedChips.province.includes(word)
          ? 'Province'
          : categorizedChips.city.includes(word)
          ? 'City/Municipality'
          : categorizedChips.barangay.includes(word)
          ? 'Barangay'
          : "Can't find"
      }))
    );
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleFieldChange = (id, newValue) => {
    setFields(prevFields =>
      prevFields.map(field =>
        field.id === id ? { ...field, value: newValue } : field
      )
    );
  };

  const handleDropToField = (id, word) => {
    setFields(prevFields =>
      prevFields.map(field =>
        field.id === id ? { ...field, value: field.value + ' ' + word } : field
      )
    );
  };

  const clearField = id => {
    setFields(prevFields =>
      prevFields.map(field =>
        field.id === id ? { ...field, value: '' } : field
      )
    );
  };

  const moveField = (fromIndex, toIndex) => {
    setFields(prevFields => {
      const updatedFields = [...prevFields];
      const [movedField] = updatedFields.splice(fromIndex, 1);
      updatedFields.splice(toIndex, 0, movedField);
      return updatedFields;
    });
  };

  const handleOkClick = () => {
    onSubmit({
      province: fields[0].value.trim(),
      city: fields[1].value.trim(),
      brgy: fields[2].value.trim(),
      street: fields[3].value.trim(),
      zip: fields[4].value.trim()
    });
    closeModal();
  };

  const openModal = () => {
    setModalOpen(true);
    processInput(inputText);
  };

  const arrayWords = arrWords.replace(/[, ]+/g, ' ');
  const filteredWords = arrayWords.trim().split(' ');

  return (
    <>
      <Box display="flex" alignItems="center">
        <TextField
          fullWidth
          variant="outlined"
          label="Paste Here"
          value={inputText}
          onChange={handleInputChange}
        />
        <Button
          variant="contained"
          onClick={openModal}
          style={{ marginLeft: '8px' }}
          color="primary"
        >
          Paste
        </Button>
      </Box>
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="word-arrange-modal"
        aria-describedby="word-arrange-description"
      >
        <Box className={classes.modalContent}>
          <Box display="flex" alignItems="center" gap={2} marginBottom={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Paste Here"
              value={inputText}
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              label="Delimiter"
              value={delimiter}
              onBlur={handleFilterBlur}
              onChange={e => setDelimiter(e.target.value)}
              placeholder="Comma, period, etc."
              style={{ width: 150, marginLeft: 5 }}
            />
          </Box>
          <DndProvider backend={HTML5Backend}>
            <Box>
              {fields.map(({ id, value }, index) => (
                <Box key={id} display="flex" alignItems="center" mb={1}>
                  <Box width="30%" textAlign="left" pr={2}>
                    <Typography variant="body1">
                      {dataLabels[index]}{' '}
                    </Typography>
                  </Box>
                  <Box width="70%">
                    <DraggableField
                      id={id}
                      addresses={restructuredAddress}
                      value={value}
                      index={index}
                      moveField={moveField}
                      onChange={handleFieldChange}
                      onDrop={handleDropToField}
                      clearField={clearField}
                      actualdata={dataUse}
                      restructuredAddres={restructuredAddress}
                      sortedChips={chips}
                      dataEditLabel={dataEditLabel}
                      wordDict={wordDict}
                      isDraggable={usablefields}
                      filteredWords={filteredWords}
                      arrWords={arrWords}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                variant="outlined"
                label="Arranged Text"
                value={arrWords}
                disabled={true}
              />
            </Box>
            <Box mb={2}>
              <Box display="flex" flexWrap="wrap">
                {chips.map((chip, index) => (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <DraggableChip word={chip.word} label={chip.category} />
                  </Box>
                ))}
              </Box>
            </Box>
          </DndProvider>
          <Box className={classes.buttonContainer}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleOkClick}
              disabled={false}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
      <SubAccForm arrangeWord={rawArrangedWords} data={restructuredAddress} />
    </>
  );
};

export default WordArrangeModal;
