import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

function TotalComponent({ row, field, value }) {
  return (
    <TextField
      size="small"
      value={value}
      select
      fullWidth
      onChange={e => row.handleChangeTotal(row.id, field, e.target.value)}
      variant="standard"
      InputProps={{
        disableUnderline: true
      }}
    >
      <MenuItem key={0} value={0}>
        Don't Show
      </MenuItem>
      {[1, 2, 3, 4, 5, 6].map(data => (
        <MenuItem key={data} value={data}>
          Total {data}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default TotalComponent;
