import { isNumber, isString } from 'lodash';

/**
 *
 * @param {string} numberString
 */
function convertToNumber(numberString) {
  if (!numberString) return 0;

  if (!isString(numberString)) {
    return isNumber(numberString) ? numberString : 0;
  }

  const value = Number(numberString);
  return isNaN(value) ? 0 : value;
}

export default convertToNumber;
