import React, { useMemo, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  TextField,
  Box,
  IconButton,
  Menu,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import WordArrangeModal, { ItemType } from './WordArranger';
import { consolidateKVSValue } from 'src/views/trans/Payroll/utils';
import { cols } from 'src/views/setup/fsLayout/FSLayoutView/components/columns';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
    cursor: 'move',
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  fieldContainer: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    border: '1px dashed #ccc',
    borderRadius: '4px',
    backgroundColor: '#f9f9f9',
    display: 'flex',
    alignItems: 'center'
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(3),
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    width: '90%',
    maxWidth: '500px'
  },
  deleteIcon: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer'
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const findNearestMatch = (word, options) => {
  return options.reduce(
    (closest, option) => {
      const distance = getStringDistance(word, option);
      if (distance < closest.distance) {
        return { option, distance };
      }
      return closest;
    },
    { option: null, distance: Infinity }
  ).option;
};

const getStringDistance = (str1, str2) => {
  let distance = 0;
  const maxLength = Math.max(str1.length, str2.length);
  for (let i = 0; i < maxLength; i++) {
    if (str1[i] !== str2[i]) distance++;
  }
  return distance;
};
const DraggableField = ({
  id,
  value,
  index,
  moveField,
  onChange,
  onDrop,
  clearField,
  addresses,
  sortedChips,
  filteredWords,
  arrWords,
  arrayword_arange,
  wordDict
}) => {
  const classes = useStyles();
  const [, dragRef] = useDrag({
    type: ItemType.FIELD,
    item: { index }
  });

  //for drop

  const [, dropRef] = useDrop({
    accept: ItemType.FIELD,
    hover: draggedItem => {
      if (draggedItem.index !== index) {
        moveField(draggedItem.index, index);
        draggedItem.index = index;
      }
    }
  });

  const handleDrop = word => {
    onDrop(id, word);
  };

  const [{ isOver }, fieldDropRef] = useDrop({
    accept: ItemType.WORD,
    drop: item => handleDrop(item.word),
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = item => {
    onChange(id, item);
    handleMenuClose();
  };

  const insertProvince = filteredWords[0];
  const insertCity = filteredWords[1];
  const province = addresses.map(prov => prov.province);

  const provIndex = addresses.findIndex(
    prov => prov.province === insertProvince
  );

  const cities = provIndex !== -1 ? addresses[provIndex].cities : [];

  const townCities = cities.map(city => city.city);

  const cityIndex = cities.findIndex(city => city.city === insertCity);
  const cityBarangays = cityIndex !== -1 ? cities[cityIndex].barangays : [];

  const barangays = cityBarangays.map(barangay => barangay.barangay);

  const dropdownItems =
    id === 'Province'
      ? province
      : id === 'City/Municipality'
      ? townCities
      : id === 'Barangay'
      ? barangays
      : [];

  const hasError = value && !dropdownItems.includes(value);

  return (
    <>
      <Box
        ref={node => dragRef(dropRef(fieldDropRef(node)))}
        className={classes.fieldContainer}
        style={{
          backgroundColor: isOver ? '#e0f7fa' : '#f9f9f9'
        }}
      >
        <TextField
          value={value}
          onChange={e => onChange(id, e.target.value)}
          variant="outlined"
          size="small"
          fullWidth
          error={hasError}
          style={{
            borderColor: hasError ? 'red' : undefined
          }}
        />
        <IconButton onClick={handleMenuOpen}>
          <SearchIcon color="primary" />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {dropdownItems.map((item, idx) => (
            <MenuItem key={idx} onClick={() => handleMenuItemClick(item)}>
              {item}
            </MenuItem>
          ))}
        </Menu>

        {value && (
          <IconButton
            className={classes.deleteIcon}
            onClick={() => clearField(id)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default DraggableField;
