import React, { useState } from 'react';
import { Button } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import SrchAcc from 'src/components/SrchAcc';
import { useSelector } from 'react-redux';

function Account({ row }) {
  const [open, setOpen] = useState(false);
  const { index, ixAcc, selectAccount, sTitle } = row;
  const { lib_accounts } = useSelector(data => data.storedValues);

  const toggle = () => {
    setOpen(prev => !prev);
  };

  const handleSelectAccount = ({ ixAcc, sAccTitle, acc_type }) => {
    selectAccount(index, sAccTitle, ixAcc, acc_type);
  };

  const handleClose = value => {
    setOpen(value);
  };

  const getAccountDescription = ixAcc => {
    return (
      lib_accounts.find(item => +item.ixAcc === +ixAcc)?.sAccTitle ?? sTitle
    );
  };

  return (
    <SrchAcc
      selectedAcc={{
        title: getAccountDescription(ixAcc),
        id: ixAcc
      }}
      showModal={open}
      view={
        <Button color="primary" onClick={toggle}>
          {ixAcc === 0 ? <SearchIcon /> : ixAcc}
        </Button>
      }
      onSelectAccount={handleSelectAccount}
      callBackCloseModal={handleClose}
      lib
    />
  );
}

export default Account;
