import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink as RouterLink } from 'react-router-dom';
import { orange } from '@material-ui/core/colors';

import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  Link
} from '@material-ui/core';
import jwt_decode from 'jwt-decode';

import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundColor: '#222',
    paddingTop: 80,
    paddingBottom: 40,
    color: '#fff',
    overflow: 'visible'
  },
  avatar: {
    backgroundColor: orange[400],
    color: theme.palette.secondary.contrastText
  },
  ulapBizFeaturesSpacing: {
    marginTop: theme.spacing(2)
  },
  image: {
    width: '180px',
    marginBottom: '.5em'
  },
  mainTitle: {
    fontSize: '2.5rem',
    fontWeight: 900
  },
  smallTitle: {
    color: orange[500]
  },
  linksMargin: {
    marginTop: '1em',
    textAlign: 'left',
    color: '#ddd'
  },
  container: {
    position: 'relative',
    zIndex: 1
  },
  radzLogo: {
    display: 'block',
    position: 'absolute',
    top: '50%',
    transform: `translateY(-50%)`,
    left: -100,
    height: '95%',
    opacity: 0.1,
    [theme.breakpoints.down('sm')]: {
      transform: `translateY(0)`,
      top: 20,

      height: '50%'
    }
  }
}));

const Footer = ({ className, ...rest }) => {
  const classes = useStyles();

  const { userToken, loginToken } = useSelector(state => state.auth);

  const isUserTokenExpired =
    userToken && !(jwt_decode(userToken).exp < Date.now() / 1000);

  const isLoginTokenExpired =
    loginToken && !(jwt_decode(loginToken).exp < Date.now() / 1000);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <img
        alt="Radztech"
        className={classes.radzLogo}
        src="/static/images/landing-page/radztech-logo.png"
      />
      <Container maxWidth="lg" className={classes.container}>
        <Box mb="3em">
          <Grid container spacing={5}>
            <Grid item xs={12} lg={3} xl={3} md={3}>
              <img
                alt="logo"
                style={{ width: 160 }}
                src="/static/images/landing-page/ulap-biz-logo-inverted.png"
              />
              <Typography color="inherit" variant="caption" display="block">
                Copyright © 2020 | All rights reserved
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3} xl={3} md={3}>
              <Typography variant="h4" style={{ fontWeight: 700 }}>
                Quick links
              </Typography>

              <Box display="flex" flexDirection="column">
                {!isUserTokenExpired || !isLoginTokenExpired ? (
                  <Link
                    component={RouterLink}
                    to="/"
                    variant="body2"
                    underline="none"
                    color="textSecondary"
                    className={classes.linksMargin}
                  >
                    Home
                  </Link>
                ) : (
                  <Link
                    component={RouterLink}
                    to="/dashboard"
                    variant="body2"
                    underline="none"
                    color="textSecondary"
                    className={classes.linksMargin}
                  >
                    Dashboard
                  </Link>
                )}

                <Link
                  component={RouterLink}
                  to="/contact-us"
                  variant="body2"
                  underline="none"
                  color="textSecondary"
                  className={classes.linksMargin}
                >
                  Contact Us
                </Link>
                <Link
                  component={RouterLink}
                  to="/app/login"
                  variant="body2"
                  underline="none"
                  color="textSecondary"
                  className={classes.linksMargin}
                >
                  Sign In
                </Link>
                <Link
                  component={RouterLink}
                  to="/app/register"
                  variant="body2"
                  underline="none"
                  color="textSecondary"
                  className={classes.linksMargin}
                >
                  Sign Up
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} lg={3} xl={3} md={3}>
              <Typography variant="h4" style={{ fontWeight: 700 }}>
                Accounting and Beyond
              </Typography>

              <Box display="flex" flexDirection="column">
                <Link
                  component={RouterLink}
                  to="/beyond-accounting/tax"
                  variant="body2"
                  underline="none"
                  color="textSecondary"
                  className={classes.linksMargin}
                >
                  UlapTax
                </Link>
                <Link
                  component={RouterLink}
                  to="/beyond-accounting/inv"
                  variant="body2"
                  underline="none"
                  color="textSecondary"
                  className={classes.linksMargin}
                >
                  UlapInventory
                </Link>
                <Link
                  component={RouterLink}
                  to="/beyond-accounting/targets"
                  variant="body2"
                  underline="none"
                  color="textSecondary"
                  className={classes.linksMargin}
                >
                  UlapTargets
                </Link>
                <Link
                  component={RouterLink}
                  to="/beyond-accounting/daloy"
                  variant="body2"
                  underline="none"
                  color="textSecondary"
                  className={classes.linksMargin}
                >
                  DaloyWorkflow
                </Link>
                <Link
                  component={RouterLink}
                  to="/beyond-accounting/payroll"
                  variant="body2"
                  underline="none"
                  color="textSecondary"
                  className={classes.linksMargin}
                >
                  UlapPayroll
                </Link>
                <Link
                  component={RouterLink}
                  to="/beyond-accounting/portal"
                  variant="body2"
                  underline="none"
                  color="textSecondary"
                  className={classes.linksMargin}
                >
                  UlapPortals
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
