import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

const COLORS = [
  {
    ixColor: 'none',
    sColor: 'None'
  },
  {
    ixColor: '#7E7E7E',
    sColor: 'Gray'
  },
  {
    ixColor: 'red',
    sColor: 'Red'
  },
  {
    ixColor: 'blue',
    sColor: 'Blue'
  },
  {
    ixColor: 'green',
    sColor: 'Green'
  }
];

function ColorSelection({ row, field, value }) {
  function getValue() {
    if (value === '') return 'none';
    else return value;
  }

  function handleChange(e) {
    const { value } = e.target;

    if (value === 'none') row.handleChangeColor(row.id, field, '');
    else row.handleChangeColor(row.id, field, value);
  }

  return (
    <TextField
      size="small"
      value={getValue()}
      select
      fullWidth
      onChange={handleChange}
      variant="standard"
      InputProps={{
        disableUnderline: true
      }}
    >
      {COLORS.map(item => (
        <MenuItem key={item.ixColor} value={item.ixColor}>
          {item.sColor}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default ColorSelection;
