import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

function RuleSelect({ row, field, value }) {
  function handleChange(e) {
    const { value } = e.target;

    if (value === 'none') {
      row.handleChangeRule(row.id, field, '');
    } else {
      row.handleChangeRule(row.id, field, value);
    }
  }

  return (
    <TextField
      size="small"
      value={value === '' ? 'none' : value}
      select
      fullWidth
      onChange={handleChange}
      variant="standard"
      InputProps={{
        disableUnderline: true
      }}
    >
      <MenuItem key={'none'} value={'none'}>
        None
      </MenuItem>
      <MenuItem key={'single'} value={'single'}>
        Single
      </MenuItem>
      <MenuItem key={'double'} value={'double'}>
        Double
      </MenuItem>
    </TextField>
  );
}

export default RuleSelect;
