import moment from 'moment';

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const TYPES = ['day', 'month', 'year', 'quarter', 'range'];
export const QUARTERS = [
  '1st Quarter',
  '2nd Quarter',
  '3rd Quarter',
  '4th Quarter'
];

export const timeStart = 'T00:00:00';
export const timeEnd = 'T23:59:59';

export const getDateValues = {
  day: ({ values, prefixText }) => {
    const { dt1 } = values;

    const date = moment(dt1);

    return {
      textValue: getDatePickerDateDescription({ ...values }, prefixText),
      dt1: date.format('YYYY-MM-DD') + timeStart,
      dt2: date.format('YYYY-MM-DD') + timeEnd
    };
  },
  month: ({ values, prefixText }) => {
    const { dt1 } = values;

    const date = moment(dt1);
    const start = date.startOf('month').format('YYYY-MM-DD') + timeStart;
    const end = date.endOf('month').format('YYYY-MM-DD') + timeEnd;

    return {
      textValue: getDatePickerDateDescription(
        { dt1: start, dt2: end },
        prefixText
      ),
      dt1: start,
      dt2: end
    };
  },
  year: ({ values, prefixText }) => {
    const { dt1 } = values;

    const date = moment(dt1);

    const start =
      date
        .startOf('year')
        .startOf('month')
        .format('YYYY-MM-DD') + timeStart;
    const end =
      date
        .endOf('year')
        .endOf('month')
        .format('YYYY-MM-DD') + timeEnd;

    return {
      textValue: getDatePickerDateDescription(
        { dt1: start, dt2: end },
        prefixText
      ),
      dt1: start,
      dt2: end
    };
  },
  quarter: ({ values, prefixText }) => {
    const dt1 = values.dt1 + timeStart;
    const dt2 = values.dt2 + timeEnd;

    return {
      textValue: getDatePickerDateDescription({ dt1, dt2 }, prefixText),
      dt1,
      dt2
    };
  },
  range: ({ values, prefixText }) => {
    const { dt1, dt2 } = values;

    const start = moment(dt1).format('YYYY-MM-DD') + timeStart;
    const end = moment(dt2).format('YYYY-MM-DD') + timeEnd;

    return {
      textValue: getDatePickerDateDescription({ dt1, dt2 }, prefixText),
      dt1: start,
      dt2: end
    };
  }
};

export const getDatePickerDateDescription = (dates, prefix) => {
  const { dt1, dt2 } = dates;

  switch (prefix) {
    case 'day':
      return `For ${formatDate(dt2, 'DMY')}`;
    case 'month':
      return `For the month ended ${formatDate(dt2, 'DMY')}`;
    case 'year':
      return `For the year ended ${formatDate(dt2, 'DMY')}`;
    case 'range':
      return `From ${formatDate(dt1, 'DMY')} to ${formatDate(dt2, 'DMY')}`;
    case 'quarter':
      const date = moment(dt2);
      return `For Q${date.quarter()} ${date.year()}`;
    default:
      return `${prefix} ${formatDate(dt2, 'DMY')}`;
  }
};

export const formatDate = (dateEntered, type) => {
  const momentDate = moment(dateEntered);

  switch (type) {
    case 'D':
      return momentDate.format('MMM DD, YYYY');
    case 'M': //month
      return momentDate.month() + 1;
    //month starts with 0 | month exact
    case 'ME':
      return momentDate.month();
    case 'Y': //year
      return momentDate.year();

    case 'DMY': //month day year
      return momentDate.format('MMM DD, YYYY');

    case 'MY': //month year
      return momentDate.format('MMM YYYY');

    case 'RAW': //raw
      return momentDate.format('YYYY-MM-DD');

    case 'YMD_WITH_TIME_START':
      return `${momentDate.format('YYYY-MM-DD')}T${timeStart}`;

    case 'YMD_WITH_TIME_ENDS':
      return `${momentDate.format('YYYY-MM-DD')}T${timeEnd}`;

    default:
      return `${momentDate.format('YYYY-MM-DD')}`;
  }
};

export const getLastDayOfTheMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};

export const getYears = () => {
  const years = [];
  for (let i = formatDate(new Date().toDateString(), 'Y'); i > 1900; i--) {
    years.push(i);
  }
  return years;
};

export function getTypeByRange(dt1 = '', dt2 = '') {
  if (!dt1 || !dt2) return 'range';

  const m_dt1 = moment(dt1);
  const m_dt2 = moment(dt2);

  if (m_dt2.diff(m_dt1, 'days') === 0) return 'day';

  const isFirstDayOfMonth = m_dt1.date() === 1;

  if (isFirstDayOfMonth && m_dt2.diff(moment(dt1).endOf('month'), 'days') === 0)
    return 'month';

  if (
    isFirstDayOfMonth &&
    m_dt2.diff(
      moment(dt1)
        .add(2, 'months')
        .endOf('month'),
      'days'
    ) === 0
  )
    return 'quarter';

  if (
    m_dt1.month() === 0 &&
    isFirstDayOfMonth &&
    m_dt2.diff(moment(dt1).endOf('year'), 'days') === 0
  )
    return 'year';

  return 'range';
}

/**
 *
 * @param {Object} value
 * @param {String} value.dt1
 * @param {String} value.dt2
 * @param {'day'|'month'|'quarter'|'year'} type
 * @param {'increment'|'decrement'} operation
 */
export function incrementOrDecrementByType(value, type, operation) {
  if (!type || !value.dt1 || !value.dt2) return value;

  const dt1 = moment(value.dt1),
    dt2 = moment(value.dt2);

  if (operation === 'increment') {
    dt1.add(1, type);
    dt2.add(1, type);
  }

  if (operation === 'decrement') {
    dt1.subtract(1, type);
    dt2.subtract(1, type);
  }

  return getDateValues[type]({
    values: {
      dt1: dt1.startOf(type).format('YYYY-MM-DD'),
      dt2: dt2.endOf(type).format('YYYY-MM-DD')
    },
    prefixText: type
  });
}
