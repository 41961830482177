import React, { useState } from 'react';
import {
  Button,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar
} from '@material-ui/core';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import TuneIcon from '@material-ui/icons/Tune';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import FormatIndentDecreaseIcon from '@material-ui/icons/FormatIndentDecrease';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import CodeIcon from '@material-ui/icons/Code';

function MenuPopOver({ row }) {
  const [anchorElement, setAnchorElement] = useState(null);
  const {
    handleAddIndent,
    handleReduceIndent,
    selectRow,
    deleteRow,
    insertRow,
    showAdvance
  } = row;

  const handleShowMenu = e => {
    setAnchorElement(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  const addIndent = () => {
    const indent = row.indent + 1;
    handleAddIndent(row.id, 'indent', indent);
  };

  const reduceIndent = () => {
    const indent = row.indent - 1;
    handleReduceIndent(row.id, 'indent', indent);
  };

  const showProperties = () => {
    selectRow(row.id);
    setAnchorElement(null);
  };

  const handleDelete = () => {
    deleteRow(row.id);
    setAnchorElement(null);
  };

  const handleInsertRow = () => {
    insertRow(row.id);
    setAnchorElement(null);
  };

  const handleShowAdvance = () => {
    showAdvance(row.id);
    setAnchorElement(null);
  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        aria-describedby={
          Boolean(anchorElement) ? 'filter-account' + row : undefined
        }
        onClick={handleShowMenu}
      >
        <SettingsOutlinedIcon />
      </Button>
      <Popover
        id={Boolean(anchorElement) ? 'filter-account' + row : undefined}
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <List>
          <ListItem button onClick={showProperties}>
            <ListItemAvatar>
              <TuneIcon />
            </ListItemAvatar>
            <ListItemText primary="Properties" />
          </ListItem>
          <ListItem button onClick={addIndent}>
            <ListItemAvatar>
              <FormatIndentIncreaseIcon />
            </ListItemAvatar>
            <ListItemText primary="Add Indent" />
          </ListItem>
          <ListItem button onClick={reduceIndent}>
            <ListItemAvatar>
              <FormatIndentDecreaseIcon />
            </ListItemAvatar>
            <ListItemText primary="Reduce Indent" />
          </ListItem>
          <ListItem button onClick={handleInsertRow}>
            <ListItemAvatar>
              <TrendingFlatIcon />
            </ListItemAvatar>
            <ListItemText primary="Insert Row" />
          </ListItem>
          <ListItem button onClick={handleShowAdvance}>
            <ListItemAvatar>
              <CodeIcon />
            </ListItemAvatar>
            <ListItemText primary="Advance Settings" />
          </ListItem>
          <ListItem button onClick={handleDelete}>
            <ListItemAvatar>
              <DeleteOutlineIcon />
            </ListItemAvatar>
            <ListItemText primary="Delete" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
}

export default MenuPopOver;
