import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';
import ExposurePlus1OutlinedIcon from '@material-ui/icons/ExposurePlus1Outlined';
import ExposureNeg1OutlinedIcon from '@material-ui/icons/ExposureNeg1Outlined';

const useStyles = makeStyles(() => ({
  redColor: { color: red[500] },
  greenColor: { color: green[500] }
}));

function ButtonToggleMultiplier({ row, field, value }) {
  const classes = useStyles();

  const handleClick = () => {
    if (value < 0) {
      row.handleToggleOperator(row.id, field, 1);
    } else {
      row.handleToggleOperator(row.id, field, -1);
    }
  };

  return (
    <Button size="small" onClick={handleClick}>
      {value === 1 ? (
        <ExposurePlus1OutlinedIcon className={classes.greenColor} />
      ) : (
        <ExposureNeg1OutlinedIcon className={classes.redColor} />
      )}
    </Button>
  );
}

export default ButtonToggleMultiplier;
