import React, { useState, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import numeral from 'numeral';
import { evaluate } from 'mathjs';
import useNotif from 'src/hooks/useNotif';
import { setSelfValueVariable } from '../utils';
import parseShortcutNumber from 'src/helpers/parseShorcutNumber';

const solveExpression = ({ expression = '', variables = {} }) => {
  const exp = [...expression];

  if (exp[0] !== '=') {
    return 'Invalid Formula';
  }

  exp.splice(0, 1); // remove '='

  try {
    return evaluate(exp.join(''), variables);
  } catch (error) {
    return 'Invalid formula';
  }
};

const getNumberOfEqualSign = formula =>
  [...formula].filter(char => char === '=').length;

const hasExcludedVariable = (excludedVariables, formula) => {
  if (excludedVariables.length === 0) return false;

  let matches = false;

  excludedVariables.forEach(excludedVariable => {
    if (formula.indexOf(excludedVariable) > -1) {
      matches = true;
    }
  });

  return matches;
};

export const NumberInput = ({
  exp = '',
  value = '',
  onBlur = () => null,
  onChange = () => null,
  onError = () => null,
  variables = {},
  excludedVariables = [],
  disableDecimal = false,
  format = '0,0.00',
  ...rest
}) => {
  const ref = useRef(null);
  const formulaRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  // formula
  const [isFormulaMode, setIsFormulaMode] = useState(false);
  const [formula, setFormula] = useState('');
  const [submitted, setSubmitted] = useState(true);

  // value number
  const [numberValue, setNumberValue] = useState(0);
  const { error: errorNotif } = useNotif();

  const { require, isEditable, placeholder, error, errorMessage } = rest;

  const setFocus = () => {
    setIsFocused(true);
    setNumberValue(value);
    setSubmitted(false);

    if (exp !== '') {
      setFormula(exp);
      setIsFormulaMode(true);
    }
  };

  const submitExpression = e => {
    setIsFocused(false);

    if (formula && formula !== '') {
      setIsFormulaMode(false);

      const expression = setSelfValueVariable({ value, formula });

      let result = solveExpression({ expression, variables });

      if (result === 'Invalid formula') {
        onChange({
          value: 0,
          formula
        });

        onError('Invalid formula ' + formula);

        return;
      }

      const finalValue = result === 0 ? +numberValue : result;

      if (hasExcludedVariable(excludedVariables, formula)) {
        onChange({
          value: finalValue,
          formula: ''
        });
      } else {
        onChange({
          value: finalValue,
          formula
        });
      }

      return;
    }

    onChange({ value: +numberValue, formula: '' });
    setIsFormulaMode(false);
  };

  const handleBlurNumericInput = () => {
    if (isFormulaMode) return;

    if (submitted) return;

    setSubmitted(true);
    submitExpression();
  };

  const handleBlurFormula = () => {
    if (isFocused && formula === '') return;

    if (submitted) return;

    setSubmitted(true);
    submitExpression();
  };

  const handleKeyPress = e => {
    let key = e.key || e.which;

    if (key === 'Enter') {
      setSubmitted(true);
      submitExpression();
    }
  };

  const handleKeyPressFormula = e => {
    let key = e.key || e.which;

    if (key === 'Enter') {
      setSubmitted(true);
      submitExpression();
    }
  };

  const handleChange = e => {
    const { value } = e.target;

    if (value === '') {
      setNumberValue(value);
      onChange({
        value: '',
        formula: ''
      });
      return;
    }

    if (value === '=') {
      setIsFormulaMode(true);
      setFormula('=');
      return;
    }

    const firstItem = value?.[0] ?? '';
    if (firstItem === '=') {
      setIsFormulaMode(true);
      setFormula(value);
      return;
    }

    if (value === '-') {
      setNumberValue(value);
      return;
    }

    if (value === '.') {
      setNumberValue('0.');
      return;
    }

    const shortcutNum = parseShortcutNumber(value);

    if (shortcutNum !== 0) {
      if (disableDecimal && shortcutNum % 1 !== 0) {
        errorNotif('Decimal not allowed.');
        return;
      }

      setNumberValue(shortcutNum);
      onChange({
        value: +shortcutNum,
        formula: ''
      });

      return;
    }

    const parsedValue = value.toString().replace(/[^a-zA-Z0-9.-]/g, '', '');

    if (isNaN(parsedValue)) return;

    if (disableDecimal && +parsedValue % 1 !== 0) {
      errorNotif('Decimal not allowed.');
      return;
    }

    setNumberValue(parsedValue);
  };

  const handleChangeFormula = e => {
    const { value } = e.target;

    if (value === '') {
      setIsFormulaMode(false);
      setFormula('');
      setNumberValue('');
      onChange({
        value: '',
        formula: ''
      });

      return;
    }

    if (getNumberOfEqualSign(value) > 1) return;

    if (value?.[0] !== '=') return;

    setFormula(value);
  };

  useEffect(() => {
    setNumberValue(value);
    setFormula(exp);
  }, [value, exp]);

  useEffect(() => {
    if (isFocused && !isFormulaMode && ref.current) {
      const len = ref.current.value.toString().length;
      ref.current.focus();
      ref.current.setSelectionRange(0, len);
    }
  }, [ref.current, isFocused, isFormulaMode]);

  useEffect(() => {
    if (isFocused && isFormulaMode && formulaRef.current)
      formulaRef.current.focus();
  }, [formulaRef.current, isFocused, isFormulaMode]);

  return (
    <>
      <TextField
        variant="outlined"
        value={numeral(value).format(disableDecimal ? '' : format)}
        required={require}
        disabled={isEditable}
        onFocus={setFocus}
        autoComplete="off"
        inputProps={{
          style: { textAlign: 'right' }
        }}
        style={{ display: !isFocused && !isFormulaMode ? 'block' : 'none' }}
        error={error}
        helperText={errorMessage}
        placeholder={placeholder}
        label={rest.label}
        fullWidth
        {...rest?.textboxProps}
      />
      <TextField
        variant="outlined"
        value={numberValue}
        required={require}
        disabled={isEditable}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        onBlur={handleBlurNumericInput}
        autoComplete="off"
        inputRef={ref}
        inputProps={{
          style: { textAlign: 'right' }
        }}
        error={error}
        helperText={errorMessage}
        placeholder={placeholder}
        label={rest.label}
        fullWidth
        style={{ display: isFocused && !isFormulaMode ? 'block' : 'none' }}
        {...rest?.textboxProps}
      />
      <TextField
        variant="outlined"
        value={formula}
        required={require}
        disabled={isEditable}
        error={error}
        helperText={errorMessage}
        onChange={handleChangeFormula}
        onKeyPress={handleKeyPressFormula}
        onBlur={handleBlurFormula}
        label={rest.label}
        fullWidth
        inputRef={formulaRef}
        style={{ display: isFocused && isFormulaMode ? 'block' : 'none' }}
        {...rest?.textboxProps}
      />
    </>
  );
};
