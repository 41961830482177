import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

function TitleAlign({ row, field, value }) {
  return (
    <TextField
      size="small"
      value={value}
      select
      fullWidth
      onChange={e => row.handleChangeTitleAlign(row.id, field, e.target.value)}
      variant="standard"
      InputProps={{
        disableUnderline: true
      }}
    >
      <MenuItem key={'left'} value={'left'}>
        Left
      </MenuItem>
      <MenuItem key={'center'} value={'center'}>
        Center
      </MenuItem>
      <MenuItem key={'right'} value={'right'}>
        Right
      </MenuItem>
    </TextField>
  );
}

export default TitleAlign;
