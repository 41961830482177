/**
 *
 * @param {subAccountDetails} sub
 */
function translateSubKvs(sub) {
  const { kvs = [], ...rest } = sub;

  const translatedKvs = {};

  for (const { key, value } of kvs) {
    translatedKvs[key] = value;
  }

  return {
    ...rest,
    kvs: translatedKvs
  };
}

export default translateSubKvs;
