import moment from 'moment';
import { round, ceil } from 'lodash';
import { evaluate } from 'mathjs';
import { SIL_TYPES } from 'src/constants';
import { formatTimeToStr } from 'src/utils';
import { Employee } from './models';

export const PAID_LEAVES = [
  SIL_TYPES.SICK_LEAVE,
  SIL_TYPES.VACATION_LEAVE,
  SIL_TYPES.SIL
];

export const defaultVarLabels = {
  vPresent: 'Present',
  vAbsent: 'Absent',
  vLate: 'Late',
  vSH: 'Special Holiday',
  vRH: 'Regular Holiday',
  vOT: 'Overtime',
  vND: 'Night Diff.',
  vSL: 'Sick Leave',
  vVL: 'Vacation Leave'
};

export const getPagibigContribution = (
  salary,
  pagibigContributionTable = {},
  hasErShare = true,
  pagibigVoluntaryEeShare = 0,
  pagibigVoluntaryErShare = 0
) => {
  const voluntaryEeShare = pagibigVoluntaryEeShare
    ? parseFloat(pagibigVoluntaryEeShare)
    : 0;
  const voluntaryErShare = pagibigVoluntaryErShare
    ? parseFloat(pagibigVoluntaryErShare)
    : 0;

  const target = (pagibigContributionTable?.rates || []).find(
    ({ min, max }) => min <= salary && max >= salary
  );
  const limit = pagibigContributionTable?.limit || 0;

  if (!target)
    return {
      min: 0,
      max: 0,
      eeRate: 0,
      erRate: 0,
      ee: 0,
      er: 0,
      eeReg: 0,
      erReg: 0
    };

  const eeLimit = target.hasOwnProperty('eeLimit') ? target.eeLimit : limit;
  const erLimit = target.hasOwnProperty('erLimit') ? target.erLimit : limit;
  const eeSalary = salary > eeLimit ? eeLimit : salary;
  const erSalary = salary > erLimit ? erLimit : salary;

  if (hasErShare) {
    const ee = round(eeSalary * target.eeRate, 4);
    const er = round(erSalary * target.erRate, 4);

    return {
      min: target.min,
      max: target.max,
      eeRate: target.eeRate,
      erRate: target.erRate,
      ee: ee + voluntaryEeShare,
      er: er + voluntaryErShare,
      eeReg: ee,
      erReg: er
    };
  } else {
    const ee = round(eeSalary * target.eeRate, 4);
    const er = round(erSalary * target.erRate, 4);

    return {
      min: target.min,
      max: target.max,
      eeRate: target.eeRate + target.erRate,
      erRate: 0,
      ee: ee + er + voluntaryEeShare,
      er: 0,
      eeReg: ee + er,
      erReg: 0
    };
  }
};

export function getGSISContribution(salary, gsisRate) {
  const { eeRate = 0, erRate = 0 } = gsisRate;

  return {
    eeRate,
    erRate,
    ee: round(salary * eeRate, 4),
    er: round(salary * erRate, 4)
  };
}

export const getPhilhealthContribution = (
  salary,
  philhealthRates,
  hasErShare
) => {
  const { rate, eeRate, erRate } = philhealthRates;

  if (hasErShare)
    return {
      rate,
      eeRate,
      erRate,
      ee: round(salary * rate * eeRate, 4),
      er: round(salary * rate * erRate, 4)
    };
  else
    return {
      rate,
      eeRate,
      erRate,
      ee: round(salary * rate * (eeRate + erRate), 4),
      er: 0
    };
};

export const getSSSContribution = (salary, table, hasErShare = true) => {
  const contribution = table.find(
    data => data.min <= salary && data.max >= salary
  );

  if (!contribution) {
    return {
      min: 0,
      max: 0,
      ee: 0,
      er: 0,
      ec: 0,
      wispEE: 0,
      wispER: 0,
      regEE: 0,
      regER: 0
    };
  }

  const eeRate = contribution.reg.ee + contribution.wisp.ee;
  const erRate =
    contribution.reg.er + contribution.ec.er + contribution.wisp.er;

  if (hasErShare)
    return {
      min: contribution.min,
      max: contribution.max,
      ee: round(eeRate, 4),
      er: round(erRate, 4),
      ec: contribution.ec.er,
      wispEE: contribution.wisp.ee,
      wispER: contribution.wisp.er,
      regEE: contribution.reg.ee,
      regER: contribution.reg.er
    };
  else
    return {
      min: contribution.min,
      max: contribution.max,
      ee: round(eeRate + erRate, 4),
      er: 0,
      ec: contribution.ec.er,
      wispEE: contribution.wisp.ee + contribution.wisp.er,
      wispER: 0,
      regEE: contribution.reg.ee + contribution.reg.er,
      regER: 0
    };
};

export const getWHTContribution = (income, table, adjustment = 0) => {
  const lookUpTable = table?.MONTHLY || [];

  const contribution = lookUpTable.find(
    data => data.min + adjustment <= income && data.max + adjustment >= income
  );

  if (!contribution) {
    return {
      min: 0,
      max: 0,
      excess: 0,
      excessRate: 0,
      tax: 0,
      excessTax: 0,
      ee: 0,
      er: 0
    };
  }

  const min = contribution.min;
  const fixedAmt = contribution.fixedAmt;
  const percent = contribution.percent;
  const diff = income - min;
  const excessTax = round(diff * percent, 4);
  const computedContribution = fixedAmt + excessTax;

  return {
    min: contribution.min + adjustment,
    max: contribution.max + adjustment,
    excess: diff,
    excessRate: percent,
    tax: fixedAmt,
    excessTax: excessTax,
    ee: round(computedContribution, 4),
    er: 0
  };
};

export const PR_TYPES = {
  monthly: 0,
  semiMonthly: 1,
  weekly: 2,
  pieceRate: 3
};

export function getRange({
  prType = PR_TYPES.semiMonthly,
  cutOff = 0,
  dateToday = moment().format(),
  createPrev = false
}) {
  const today = moment(dateToday);

  if (prType === PR_TYPES.monthly)
    return {
      pr_dt1: today.startOf('month').format('YYYY-MM-DD'),
      pr_dt2: today.endOf('month').format('YYYY-MM-DD')
    };

  if (prType === PR_TYPES.semiMonthly) {
    if (createPrev) {
      today.subtract(15 - cutOff, 'days');
      dateToday = today.format();
    }

    if (moment(dateToday).date() <= 15 - cutOff) {
      const daysInMonthOfPrevMonth = moment(dateToday)
        .subtract(1, 'month')
        .daysInMonth();

      const diff =
        30 -
        daysInMonthOfPrevMonth +
        (daysInMonthOfPrevMonth === 31 && cutOff === 0 ? 1 : 0);

      return {
        pr_dt1: moment(dateToday)
          .startOf('month')
          .subtract(cutOff - diff, 'days')

          .format('YYYY-MM-DD'),
        pr_dt2: moment(dateToday)
          .date(15 - cutOff)
          .format('YYYY-MM-DD')
      };
    } else {
      const daysInMonth = today.daysInMonth();
      const diff = 30 - daysInMonth + (daysInMonth === 31 ? 1 : 0);

      return {
        pr_dt1: moment(dateToday)
          .date(16 - cutOff)
          .format('YYYY-MM-DD'),
        pr_dt2: moment(dateToday)
          .endOf('month')
          .subtract(cutOff - diff, 'days')
          .format('YYYY-MM-DD')
      };
    }
  }

  return {
    pr_dt1: moment(dateToday).format('YYYY-MM-DD'),
    pr_dt2: moment(dateToday).format('YYYY-MM-DD')
  };
}

export function consolidateKVSValue(kvs = {}) {
  let kvsValue = {};

  Object.entries(kvs).forEach(([key, { value }]) => {
    kvsValue[key] = value || 0;
  });

  return kvsValue;
}

export function getKVSValue(sub, key = '', defaultValue = '') {
  const kvs = (sub?.kvs || []).find(item => item.key === key);

  return kvs ? kvs.value : defaultValue;
}

export function getEmpField(employee, key, defaultValue = '') {
  const pr = (employee?.kvs || []).find(item => item.key === 'pr')?.value || {};

  return pr?.[key] || defaultValue;
}

export function getBasicPay(type, salary, salaryType, workingDaysCount) {
  if (salaryType === 'monthly') {
    if (type === PR_TYPES.monthly) return salary;
    if (type === PR_TYPES.semiMonthly) return salary / 2;
    return 0;
  }

  if (salaryType === 'daily') return round(salary * workingDaysCount, 4);

  if (salaryType === 'hourly') return round(salary * workingDaysCount * 8, 4);

  return 0;
}

/**
 *
 * @param {subAccountDetails} sub
 * @returns {number[]}
 */
export function listIncludedDeduction(sub) {
  const included = getKVSValue(sub, 'otherDeductions') || {
    items: []
  };

  return included.items.map(item => parseInt(item.ixSub));
}

/**
 *
 * @param {subAccountDetails} sub
 * @returns {number[]}
 */
export function listIncludedEarning(sub) {
  const included = getKVSValue(sub, 'otherEarnings') || {
    items: []
  };

  return included.items.map(item => parseInt(item.ixSub));
}

/**
 *
 * @param {subAccountDetails} sub
 * @returns {number[]}
 */
export function listIncludedPrevEarning(sub) {
  const included = getKVSValue(sub, 'includedPrevEarnings') || {
    items: []
  };

  return included.items.map(item => parseInt(item.ixSub));
}

/**
 *
 * @param {subAccountDetails} sub
 * @returns {number[]}
 */
export function listIncludedPrevDeduction(sub) {
  const included = getKVSValue(sub, 'includedPrevDeductions') || {
    items: []
  };

  return included.items.map(item => parseInt(item.ixSub));
}

export function solveExpression(expression, variables) {
  try {
    return round(evaluate(expression, variables), 4);
  } catch (error) {
    return 0;
  }
}

export function getSalaryBasis({ type = '', empInfo = {}, salary = 0 }) {
  const getVal = (key, salary) =>
    parseFloat(getEmpField(empInfo, key) || '0') || salary;

  switch (type) {
    case 'sss':
      return getVal('baseSalarySSS', salary);
    case 'wht':
      return getVal('baseSalaryWHT', salary);
    case 'pagibig':
      return getVal('baseSalaryPagibig', salary);
    case 'philhealth':
      return getVal('baseSalaryPhilhealth', salary);
    default:
      return salary;
  }
}

export function countWorkingDays(pr_dt1, pr_dt2, workDays) {
  const restDay = getEmployeeRestDay(workDays);
  const dt1 = moment(pr_dt1);
  const dt2 = moment(pr_dt2);

  let days = 0;

  while (dt1.isSameOrBefore(dt2)) {
    if (!restDay.includes(dt1.isoWeekday())) days++;

    dt1.add(1, 'day');
  }

  return days;
}

export function convertToDays(value = 0, unit = '') {
  switch (unit) {
    case 'minutes':
      return ceil(value / 60 / 24);
    case 'hours':
      return ceil(value / 24);
    case 'days':
      return value;
    default:
      return value;
  }
}

/**
 *
 * @param {Employee} employees
 * @returns {Number}
 */
export function getMaxLogColumn(employees = []) {
  let max = 4;

  for (let i = 5; i <= 12; i++) {
    const prop = 'BIO' + i;

    if (
      employees.every(employee => employee.logs.every(log => log[prop] === 0))
    )
      break;
    max++;
  }

  return max;
}

export const dayInWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
export const dayValueInWeek = {
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
  sun: 7
};

/**
 *
 * @param {Object} [workDays = {}]
 * @param {Boolean} [workDays.mon = false]
 * @param {Boolean} [workDays.tue = false]
 * @param {Boolean} [workDays.wed = false]
 * @param {Boolean} [workDays.thu = false]
 * @param {Boolean} [workDays.fri = false]
 * @param {Boolean} [workDays.sat = false]
 * @param {Boolean} [workDays.sun = false]
 * @returns {Number[]}
 */
export function getEmployeeRestDay(workDays = {}) {
  const restDay = [];

  dayInWeek.forEach(day => {
    if (!workDays?.[day]) restDay.push(dayValueInWeek[day]);
  });

  return restDay;
}

export const PR_PRIVILEGES = [
  'pr.add-employee',
  'pr.bio.import',
  'pr.bio.update',
  'pr.copy-payroll',
  'pr.print.ded-sum',
  'pr.print.earn-sum',
  'pr.print.loan-sum',
  'pr.print.payslip',
  'pr.print.register',
  'pr.rep.13mo',
  'pr.rep.annual',
  'print'
];

export const PR_PRIVILEGES_DEFAULT = {
  'pr.add-employee': false,
  'pr.bio.import': false,
  'pr.bio.update': false,
  'pr.copy-payroll': false,
  'pr.print.ded-sum': false,
  'pr.print.earn-sum': false,
  'pr.print.loan-sum': false,
  'pr.print.payslip': false,
  'pr.print.register': false,
  'pr.rep.13mo': false,
  'pr.rep.annual': false,
  print: false
};

/**
 *
 * @param {String} time1 time in HH:mm format
 * @param {String} time2 time in HH:mm format
 * @returns {Number} time in HHmm format
 */
function getMiddleOfTwoTimes(time1, time2) {
  const m_time1 = moment(time1, 'HH:mm');
  const m_time2 = moment(time2, 'HH:mm');

  if (m_time2.isSameOrBefore(m_time1)) m_time2.add(1, 'day');

  const diff = m_time2.diff(m_time1, 'minutes');
  m_time1.add(round(diff / 4), 'minutes');

  return parseInt(m_time1.format('HHmm'));
}

/**
 *
 * @param {Object} param
 * @param {Array<Number>} param.logs
 * @param {Object} param.workHours
 * @param {string} param.workHours.am_in
 * @param {string} param.workHours.am_out
 * @param {string} param.workHours.pm_in
 * @param {string} param.workHours.pm_out
 * @returns {[Number, Number, Number, Number]}
 */
function generateCorrectLogsNoNoonLogs({ logs = [], workHours }) {
  const { am_in, pm_out } = workHours;

  const len = logs.length;
  const res = [logs?.[0] || 0, logs?.[1] || 0, logs?.[2] || 0, logs?.[3] || 0];

  const mid = getMiddleOfTwoTimes(am_in, pm_out);

  if (len === 2) {
    res[3] = res[1];
    res[1] = 0;
  } else if (len === 1 && logs[0] > mid) {
    res[3] = res[0];
    res[0] = 0;
  } else if (len > 2) {
    res[3] = logs[logs.length - 1];
  }

  return res;
}

/**
 *
 * @param {Object} param
 * @param {Array<Number>} param.logs
 * @param {Object} param.workHours
 * @param {string} param.workHours.am_in
 * @param {string} param.workHours.am_out
 * @param {string} param.workHours.pm_in
 * @param {string} param.workHours.pm_out
 * @param {Boolean} param.noNoonLogs
 * @returns {[Number, Number, Number, Number]}
 */
export function generateCorrectLogs({
  logs = [],
  workHours,
  noNoonLogs = false
}) {
  if (noNoonLogs) return generateCorrectLogsNoNoonLogs({ logs, workHours });

  const { am_in, am_out, pm_in, pm_out } = workHours;

  const len = logs.length;
  const res = [logs?.[0] || 0, logs?.[1] || 0, logs?.[2] || 0, logs?.[3] || 0];

  const am_mid = getMiddleOfTwoTimes(am_in, am_out);
  const brk_mid = getMiddleOfTwoTimes(am_out, pm_in);
  const pm_mid = getMiddleOfTwoTimes(pm_in, pm_out);

  if (len === 1) {
    if (logs[0] <= am_out && logs[0] >= am_mid) {
      res[1] = logs[0];
      res[0] = 0;
    } else if (logs[0] >= am_out && logs[0] <= pm_in) {
      if (logs[0] <= brk_mid) res[1] = logs[0];
      else res[2] = logs[0];
      res[0] = 0;
    } else if (logs[0] >= pm_in) {
      if (logs[0] <= pm_mid) res[2] = logs[0];
      else res[3] = logs[0];
      res[0] = 0;
    }
  } else if (len === 2) {
    if (res[0] > am_out) {
      res[2] = logs[0];
      res[0] = 0;
    }

    if (res[2]) {
      res[3] = res[1];
      res[1] = 0;
    } else if (logs[1] > am_out) {
      if (logs[1] < pm_mid) res[2] = res[1];
      else res[3] = res[1];
      res[1] = 0;
    }
  } else if (len === 3) {
    if (logs[0] < pm_in && logs[0] > am_mid) {
      res[1] = logs[0];
      res[0] = 0;
    }

    if (logs[2] > am_out && logs[2] > pm_mid) {
      res[3] = logs[2];
      res[2] = 0;
    }

    if (res[0] !== 0 && res[3] !== 0 && logs[1] > brk_mid) {
      res[2] = logs[1];
      res[1] = 0;
    } else if (res[0] === 0) {
      res[2] = logs[1];
    }
  } else if (len > 4) {
    res[2] = logs[len - 2];
    res[3] = logs[len - 1];
  }

  return res;
}
/**
 *
 * @param {{allow: boolean, default: any, scheds: Array<{id: any, caption: string, updates: object}>}} fixedSched
 */
export function getDefaultShift(fixedSched) {
  const schedSettings = {};

  const sched = (fixedSched?.scheds || []).find(
    item => item.id === fixedSched?.default || 0
  );

  if (sched) {
    Object.keys(sched?.updates || {}).forEach(key => {
      schedSettings[key] = sched.updates[key];
    });
  }

  return {
    am_in: formatTimeToStr(schedSettings?.SHF1_HR_IN || 0),
    am_out: formatTimeToStr(schedSettings?.SHF1_HR_OUT || 0),
    pm_in: formatTimeToStr(schedSettings?.SHF2_HR_IN || 0),
    pm_out: formatTimeToStr(schedSettings?.SHF2_HR_OUT || 0)
  };
}

export const TIME_LOGS_ROW_HEIGHT = 36;
export const TIME_LOGS_HEADER_HEIGHT = 40;

export const equivalentSystemVar = {
  vOT: 'svOT',
  vOT_RH: 'svOT_RH',
  vOT_SH: 'svOT_SH',
  vOT_ND: 'svOT_ND',
  vOT_ND_RH: 'svOT_ND_RH',
  vOT_ND_SH: 'svOT_ND_SH',
  vOT_RD: 'svOT_RD',
  vOT_RD_OT: 'svOT_RD_OT',
  vOT_RD_RH: 'svOT_RD_RH',
  vOT_RD_SH: 'svOT_RD_SH',
  vND: 'svND',
  vND_RH: 'svND_RH',
  vND_SH: 'svND_SH',
  vND_RD: 'svND_RD',
  vND_RD_RH: 'svND_RD_RH',
  vND_RD_SH: 'svND_RD_SH',
  vPresent: 'svPresent',
  vHrs: 'svHrs',
  vAbsent: 'svAbsent',
  vLate: 'svLate',
  vUT: 'svUT',
  vOB: 'svOB',
  vRH: 'svRH',
  vSH: 'svSH',
  vSL: 'svSL',
  vVL: 'svVL',
  vML: 'svML',
  vPL: 'svPL',
  vAWOL: 'svAWOL',
  var1: 'svar1',
  var2: 'svar2',
  var3: 'svar3',
  var4: 'svar4',
  var5: 'svar5',
  var6: 'svar6',
  var7: 'svar7',
  var8: 'svar8',
  var9: 'svar9'
};

export function getPreviousBscPay(previousPayroll, ixEmp = 0) {
  if (!ixEmp) return 0;

  const employees = previousPayroll?.trans_pr?.items || [];
  const employee = employees.find(item => item.ixEmp === ixEmp);

  return employee ? employee.BscPay : 0;
}
