import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

function AmtPosition({ row, field, value }) {
  return (
    <TextField
      size="small"
      value={value}
      select
      fullWidth
      onChange={e => row.handleChangeAmtPosition(row.id, field, e.target.value)}
      variant="standard"
      InputProps={{
        disableUnderline: true
      }}
    >
      <MenuItem key={0} value={0}>
        None
      </MenuItem>
      <MenuItem key={1} value={1}>
        Column 1
      </MenuItem>
      <MenuItem key={2} value={2}>
        Column 2
      </MenuItem>
      <MenuItem key={3} value={3}>
        Column 3
      </MenuItem>
    </TextField>
  );
}

export default AmtPosition;
