import React from 'react';
import ButtonToggleSign from './ButtonToggleSign';
import MenuPopOver from './MenuPopOver';
import Account from './Account';
import AmtPosition from './AmtPosition';
import ButtonToggleMultiplier from './ButtonToggleMultiplier';
import TotalComponent from './Total';
import RuleSelect from './RuleSelect';
import TitleAlign from './TitleAlign';
import ColorSelection from './ColorSelection';

const cols = [
  {
    sortable: false,
    field: 'adv',
    headerName: ' ',
    width: 50,
    align: 'center',
    hideOnFilters: true,
    renderCell: data => <MenuPopOver {...data} />
  },
  {
    sortable: false,
    field: 'ixAcc',
    headerName: 'ID',
    width: 50,
    align: 'center',
    renderCell: data => <Account {...data} />
  },
  {
    sortable: false,
    field: 'prefix',
    headerName: 'Prefix',
    width: 150,
    editable: true
  },
  {
    sortable: false,
    field: 'sTitle',
    headerName: 'Title',
    flex: 1,
    minWidth: 350,
    editable: true,
    renderCell: ({ row }) => (
      <p style={{ marginLeft: `${row.indent}em` }}>{row.sTitle}</p>
    )
  },
  {
    sortable: false,
    field: 'sTitle_negative',
    headerName: 'Negative Title',
    flex: 1,
    minWidth: 350,
    editable: true,
    hide: true
  },

  {
    sortable: false,
    field: 'indent',
    headerName: 'Indent',
    width: 80,
    editable: true,
    align: 'center',
    type: 'number'
  },
  {
    sortable: false,
    field: 'color',
    headerName: 'Color',
    width: 130,

    hide: true,
    renderCell: data => <ColorSelection {...data} />
  },
  {
    sortable: false,
    field: 'color_negative',
    headerName: 'Color Negative',
    width: 130,

    hide: true,
    renderCell: data => <ColorSelection {...data} />
  },
  {
    sortable: false,
    field: 'amt_position',
    headerName: 'Amt Position',
    width: 130,
    renderCell: data => <AmtPosition {...data} />
  },
  {
    sortable: false,
    field: 'tag',
    headerName: 'Tag',
    width: 80,
    editable: true,
    hide: true
  },
  {
    sortable: false,
    field: 'hidden',
    headerName: 'Hidden',
    width: 100,
    type: 'boolean',
    editable: true,
    hide: true
  },
  {
    sortable: false,
    field: 'hideifzero',
    headerName: 'Hide if Zero',
    width: 130,
    type: 'boolean',
    editable: true,
    hide: true
  },
  {
    sortable: false,
    field: 'bPercent',
    headerName: 'Show Percent',
    width: 130,
    type: 'boolean',
    editable: true,
    hide: true
  },
  {
    sortable: false,
    field: 'vPercent',
    headerName: 'Value Percent',
    width: 130,
    editable: true,
    hide: true
  },
  {
    sortable: false,
    field: 'total',
    headerName: 'Total 1',
    width: 130,
    editable: true,
    hide: true,
    renderCell: data => <TotalComponent {...data} />
  },

  {
    sortable: false,
    field: 'total2',
    headerName: 'Total 2',
    width: 130,
    editable: true,
    hide: true,
    renderCell: data => <TotalComponent {...data} />
  },
  {
    sortable: false,
    field: 'total3',
    headerName: 'Total 3',
    width: 130,
    editable: true,
    hide: true,
    renderCell: data => <TotalComponent {...data} />
  },
  {
    sortable: false,
    field: 'total_position',
    headerName: 'Total Column 1',
    width: 130,
    editable: true,
    hide: true,
    renderCell: data => <AmtPosition {...data} />
  },
  {
    sortable: false,
    field: 'total_position2',
    headerName: 'Total Column 2',
    width: 130,
    editable: true,
    hide: true,
    renderCell: data => <AmtPosition {...data} />
  },
  {
    sortable: false,
    field: 'total_position3',
    headerName: 'Total Column 3',
    width: 130,
    editable: true,
    hide: true,
    renderCell: data => <AmtPosition {...data} />
  },
  {
    sortable: false,
    field: 'total_reset1',
    headerName: 'Total Reset 1',
    width: 130,
    editable: true,
    type: 'boolean',
    hide: true
  },
  {
    sortable: false,
    field: 'total_reset2',
    headerName: 'Total Reset 2',
    width: 130,
    editable: true,
    type: 'boolean',
    hide: true
  },
  {
    sortable: false,
    field: 'total_reset3',
    headerName: 'Total Reset 3',
    width: 130,
    editable: true,
    type: 'boolean',
    hide: true
  },
  {
    sortable: false,
    field: 'total_reset4',
    headerName: 'Total Reset 4',
    width: 130,
    editable: true,
    type: 'boolean',
    hide: true
  },
  {
    sortable: false,
    field: 'total_reset5',
    headerName: 'Total Reset 5',
    width: 130,
    editable: true,
    type: 'boolean',
    hide: true
  },
  {
    sortable: false,
    field: 'total_reset6',
    headerName: 'Total Reset 6',
    width: 130,
    editable: true,
    type: 'boolean',
    hide: true
  },
  {
    sortable: false,
    field: 'align_title',
    headerName: 'Align Title',
    width: 130,
    editable: true,
    hide: true,
    renderCell: data => <TitleAlign {...data} />
  },
  {
    sortable: false,
    field: 'bold_title',
    headerName: 'Bold Title',
    width: 130,
    editable: true,
    type: 'boolean',
    hide: true
  },
  {
    sortable: false,
    field: 'bold_amt1',
    headerName: 'Bold Amt 1',
    width: 120,
    editable: true,
    type: 'boolean',
    hide: true
  },
  {
    sortable: false,
    field: 'bold_amt2',
    headerName: 'Bold Amt 2',
    width: 120,
    editable: true,
    type: 'boolean',
    hide: true
  },
  {
    sortable: false,
    field: 'bold_amt3',
    headerName: 'Bold Amt 3',
    width: 120,
    editable: true,
    type: 'boolean',
    hide: true
  },
  {
    sortable: false,
    field: 'amt_multiplier',
    headerName: 'Multiplier',
    width: 100,
    align: 'center',
    renderCell: data => <ButtonToggleMultiplier {...data} />
  },

  {
    sortable: false,
    field: 'total_operator1',
    headerName: 'Total 1',
    width: 90,
    align: 'center',
    renderCell: data => <ButtonToggleSign {...data} />
  },
  {
    sortable: false,
    field: 'total_operator2',
    headerName: 'Total 2',
    width: 90,
    align: 'center',
    renderCell: data => <ButtonToggleSign {...data} />
  },
  {
    sortable: false,
    field: 'total_operator3',
    headerName: 'Total 3',
    width: 90,
    align: 'center',
    renderCell: data => <ButtonToggleSign {...data} />
  },
  {
    sortable: false,
    field: 'total_operator4',
    headerName: 'Total 4',
    width: 90,
    align: 'center',
    renderCell: data => <ButtonToggleSign {...data} />
  },
  {
    sortable: false,
    field: 'total_operator5',
    headerName: 'Total 5',
    width: 90,
    align: 'center',
    renderCell: data => <ButtonToggleSign {...data} />
  },
  {
    sortable: false,
    field: 'total_operator6',
    headerName: 'Total 6',
    width: 90,
    align: 'center',
    renderCell: data => <ButtonToggleSign {...data} />
  },

  {
    sortable: false,
    field: 'rule_top1',
    headerName: 'Rule Top 1',
    width: 120,
    editable: true,
    hide: true,
    renderCell: data => <RuleSelect {...data} />
  },
  {
    sortable: false,
    field: 'rule_top2',
    headerName: 'Rule Top 2',
    width: 120,
    editable: true,
    hide: true,
    renderCell: data => <RuleSelect {...data} />
  },
  {
    sortable: false,
    field: 'rule_top3',
    headerName: 'Rule Top 3',
    width: 120,
    editable: true,
    hide: true,
    renderCell: data => <RuleSelect {...data} />
  },
  {
    sortable: false,
    field: 'rule_bottom1',
    headerName: 'Rule Bottom 1',
    width: 140,
    editable: true,
    hide: true,
    renderCell: data => <RuleSelect {...data} />
  },
  {
    sortable: false,
    field: 'rule_bottom2',
    headerName: 'Rule Bottom 2',
    width: 140,
    editable: true,
    hide: true,
    renderCell: data => <RuleSelect {...data} />
  },
  {
    sortable: false,
    field: 'rule_bottom3',
    headerName: 'Rule Bottom 3',
    width: 140,
    editable: true,
    hide: true,
    renderCell: data => <RuleSelect {...data} />
  }
];

const PROPERTIES = [
  'tag',
  'hidden',
  'hideifzero',
  'bPercent',
  'vPercent',
  'total',
  'total1',
  'total_position',
  'total_reset1',
  'total_reset2',
  'total_reset3',
  'total_reset4',
  'total_reset5',
  'total_reset6',
  'bold_title',
  'align_title',
  'bold_amt1',
  'bold_amt2',
  'bold_amt3',
  'color',
  'color_negative',
  'sTitle_negative'
];

const RULE_PROPERTIES = [
  'rule_top1',
  'rule_top2',
  'rule_top3',
  'rule_bottom1',
  'rule_bottom2',
  'rule_bottom3'
];

const TOTAL_ADV = ['total2', 'total3', 'total_position2', 'total_position3'];

export { RULE_PROPERTIES, PROPERTIES, TOTAL_ADV, cols };
