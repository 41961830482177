import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import largeData from "./addresses.json"; 
import { Button, TextField, CircularProgress } from "@material-ui/core";

const SearchData = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fuse, setFuse] = useState(null);

  useEffect(() => {
    const fuseInstance = new Fuse(largeData, {
      keys: ["region", "rate", "stateProv", "cityMuni", "townBar"],
      threshold: 0.4,
    });
    setFuse(fuseInstance);
  }, []);

  const handleSearch = () => {
    if (!query.trim() || !fuse) {
      setResults([]);
      return;
    }

    setLoading(true);
    setTimeout(() => {
      const matches = fuse.search(query);
      setResults(matches.map(match => match.item));
      setLoading(false);
    }, 300);
  };

  const handlePaste = (e) => {
    const text = e.clipboardData.getData("Text");
    setQuery(text);
  };

  return (
    <div style={{ width: "300px", margin: "0 auto", textAlign: "center" }}>
      <TextField
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onPaste={handlePaste}
        placeholder="Paste or type address..."
        fullWidth
        variant="outlined"
        margin="normal"
      />
      <Button
        onClick={handleSearch}
        variant="contained"
        color="primary"
        style={{ marginBottom: "8px" }}
      >
        Search
      </Button>
      {loading && <CircularProgress size={24} style={{ margin: "8px 0" }} />}

      <ul>
        {results.length > 0 ? (
          results.map((result, index) => (
            <li key={index}>
              <strong>{result.stateProv}</strong> - {result.cityMuni}, {result.townBar}
            </li>
          ))
        ) : (
          !loading && <li>No results found</li>
        )}
      </ul>
    </div>
  );
};

export default SearchData;
